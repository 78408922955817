import { initReactI18next } from "react-i18next";

import i18n from "i18next";
import th from "./th.json";
import en from "./en.json";

const language = localStorage.getItem("language");

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    th: {
      translation: th,
    },
  },
  fallbackLng: language ? language : "th",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
