export const suggestions = [
  // Drinks
  "เหล้า",
  "น้ำแข็ง",
  "น้ำเปล่า",
  "เบียร์",
  "เบียร์สิงห์",
  "เบียร์ช้าง",
  "เบียร์ลีโอ",
  "ไวน์",
  "รีเจนซีแบน",
  "รีเจนซีกลม",
  "แสงโสมแบน",
  "แสงโสมกลม",
  "เฟร้นฟราย",
  "ซอยจุ๊",
  "เอ็นไก่ทอด",
  "พิซซ่า",
  "ไก่ทอด",
  "ต้มยำกุ้ง",
  "ผัดไทย",
  "ข้าวมันไก่",
  "ส้มตำ",
  "ลาบ",
  "ยำวุ้นเส้น",
  "แกงส้ม",
  "แกงเขียวหวาน",
  "ข้าวเหนียวมะม่วง",
  "มะม่วงปิดตาย",
  "ขนมจีน",
  "แกงเป็ด",
  "ข้าวซอย",
  "ข้าวผัด",
  "ต้มแซ่บ",
  "หมูกรอบ",
  "ผัดซีอิ๊ว",
  "ผัดกระเพรา",
  "แกงป่า",
  "ยำถั่วพู",
  "ยำปลาดุกฟู",
  "ฟักทองชีส",
  "ปลากระพงนึ่งมะนาว",
  "ไก่ย่าง",
  "ยำเป็ดย่าง",
  "ข้าวคลุกกะปิ",
  "สปาเก็ตตี้ผัดขี้เมา",
  "ข้าวหมกไก่",
  "ต้มข่าไก่",
  "ยำทะเล",
  "หมูสะเต๊ะ",
  "ยำเห็ด",
  "เห็ดนึ่งนมสด",
  "หมูมะนาว",
  "ปูผัดผงกระหรี่",
  "ยำกุ้งแซ่บ",
  "เส้นใหญ่หมูแดง",
  "เกี๊ยวน้ำ",
  "ปูนิ่มทอดกระเทียม",
  "ปลาสลิดทอด",
  "แกงคั่วกุ้ง",
  "น้ำตกหมู",
  "แซลมอนย่าง",
  "กุ้งอบวุ้นเส้น",
  "ไก่ทอดเกาหลี",
  "กุ้งชุบแป้งทอด",
  "หมูปิ้ง",
  "กะเพราไก่ไข่ดาว",
  "ข้าวหน้าเป็ด",
  "กุ้งแม่น้ำผัดพริกแกง",
  "หอยแมลงภู่ทอด",
  "น้ำพริกกุ้งสด",
  "ยำรวมมิตร",
  "กุ้งเผา",
  "แกงจืด",
  "แกงส้มชะอมกุ้ง",
  "แกงป่าไก่",
  "ข้าวผัดปู",
  "หอยแมลงภู่ผัดพริกเผา",
  "ข้าวจี่",
  "แกงเห็ด",
  "เต้าหู้ทอด",
  "ไข่ตุ๋น",
  "ยำแซลมอน",
  "กุ้งย่าง",
  "ปลาหมึกย่าง",
  "ยำหมูยอ",
  "ซุปหัวปลา",
  "ต้มยำปลา",
  "ต้มยำทะเล",
  "แกงคั่ว",
  "กุ้งผัดวุ้นเส้น",
  "หอยลายผัดพริกเผา",
  "ยำกุ้งเผา",
  "ยำปลากระพง",
  "ปลาดุกผัดฉ่า",
  "ข้าวผัดหมู",
  "หมูทอดกระเทียมพริกไทย",
  "ปลานึ่งซีอิ๊ว",
  "กุ้งผัดกระเทียม",
  "ต้มข่าปลา",
  "ยำขนมจีน",
  "ยำหมูกรอบ",
  "เป็ดย่าง",
];
