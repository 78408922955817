import { useEffect, useState } from "react";
import { Table, Button, message, Modal, Popover } from "antd";
import { DatePicker } from "antd";

import { collection, query, where, getDocs, deleteDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase_db";
import dayjs from "dayjs";
import "./style.scss";
import GoogleAuthen from "../Bill/GoogleAuthen";
import { BillEach } from "../Bill/Bill";
import { ColumnType } from "antd/es/table";
import { EyeOutlined, DeleteOutlined, EditOutlined, ShareAltOutlined, MenuOutlined } from "@ant-design/icons";
import useUserStore from "../Bill/userStore";
import { useTranslation } from "react-i18next";
import { Divider } from "antd";

export default function MyBills() {
  const { RangePicker } = DatePicker;
  const [dateRange, setDateRange] = useState<(dayjs.Dayjs | null)[]>([null, null]);
  const [totalBillPrice, setTotalBillPrice] = useState<number>(0);
  const { t } = useTranslation();
  const [bills, setBills] = useState<BillEach[]>([]);
  const [isModaldeleteBillOpen, setIsModaldeleteBillOpen] = useState(false);
  const [billSelected, setBillSelected] = useState<any>(undefined);
  const [tableLoading, setTableLoading] = useState(true);
  const [top5People, setTop5People] = useState([] as [string, number][]);
  const { userDetail } = useUserStore();
  const [filteredBills, setFilteredBills] = useState<BillEach[]>([]);

  useEffect(() => {
    if (dateRange && dateRange[0] && dateRange[1]) {
      const filteredBills = bills.filter((bill) => {
        const billDate = bill.timestamp?.seconds ? new Date(bill.timestamp.seconds * 1000) : null;
        return billDate && billDate >= dayjs(dateRange[0]).toDate() && billDate <= dayjs(dateRange[1]).toDate();
      });
      setFilteredBills(filteredBills);

      // Create an object to track the total amount each person paid.
      const peopleTotal: Record<string, number> = {};

      filteredBills.forEach((bill) => {
        if (bill.dataMenu) {
          // Check if dataMenu exists
          bill.dataMenu.forEach((menu) => {
            const pricePerPerson = Number(menu.price) / menu.selectedPeople.length;

            menu.selectedPeople.forEach((person) => {
              // If the person is not in the peopleTotal object, initialize their total to 0.
              if (!peopleTotal[person]) peopleTotal[person] = 0;

              // Add the menu price for this person.
              peopleTotal[person] += pricePerPerson;
            });
          });
        }
      });

      const sortedPeople: [string, number][] = Object.entries(peopleTotal).sort((a, b) => b[1] - a[1]);

      const top5Peoples = sortedPeople.slice(0, 5);

      setTop5People(top5Peoples);

      const totalPrice = filteredBills.reduce((acc, bill) => {
        const billTotal = bill?.dataMenu?.reduce((menuAcc, menu) => menuAcc + Number(menu.price), 0);
        if (billTotal) {
          return acc + billTotal;
        } else {
          return acc;
        }
      }, 0);
      setTotalBillPrice(totalPrice);
    } else {
      setTotalBillPrice(0);
      setTop5People([]);
      setFilteredBills(bills);
    }
  }, [bills, dateRange]);

  useEffect(() => {
    if (userDetail) {
      const fetchBills = async () => {
        const userBillsRef = collection(db, "users", userDetail.uid, "userBills");
        const userBillsSnapshot = await getDocs(userBillsRef);

        const billIds = userBillsSnapshot.docs.map((doc) => doc.data().billId);

        const billsCollection = collection(db, "bills");
        const billsPromises = billIds.map((billId) => getDoc(doc(billsCollection, billId)));

        const billsDetails = await Promise.all(billsPromises);
        const billsData = billsDetails
          .map((billDoc) => ({
            key: billDoc.id,
            ...billDoc.data(),
          }))
          .sort((a: any, b: any) => b.timestamp?.toDate().getTime() - a.timestamp?.toDate().getTime());
        if (billsData.length >= 0) {
          setTableLoading(false);
        }
        setBills(billsData as BillEach[]);
      };

      fetchBills();
    }
  }, [userDetail]);

  const handleDelete = async (billId: string) => {
    setIsModaldeleteBillOpen(false);
    try {
      await deleteDoc(doc(db, "bills", billId));

      const userBillRef = collection(db, "users", userDetail.uid, "userBills");
      const userBillQuery = query(userBillRef, where("billId", "==", billId));
      const userBillSnapshot = await getDocs(userBillQuery);

      if (!userBillSnapshot.empty) {
        const userBillDocId = userBillSnapshot.docs[0].id;
        await deleteDoc(doc(userBillRef, userBillDocId));
      }

      setBills((prevBills) => prevBills && prevBills.filter((bill) => bill.key !== billId));

      message.success(`ลบบิลสำเร็จ`);
    } catch (error) {
      console.error("ลบบิลไม่สำเร็จ", error);
    }
  };

  const columns: ColumnType<BillEach>[] = [
    {
      title: t("myBill.myBillOrder"),
      render: (text: any, record: any, index: number) => <span>{index + 1}</span>,
      key: "order",
      responsive: ["sm"],
    },
    {
      title: t("myBill.myBillName"),
      dataIndex: "billName",
      key: "billName",
      // create link
      render: (text: any, record: any) => (
        <a
          href={`/view-bill/${record.key}`}
          rel="noreferrer"
          style={{ textDecoration: "underLine", color: "rgba(0, 0, 0, 0.88)" }}
        >
          {text}
        </a>
      ),
    },
    {
      title: t("myBill.myBillTimeStamp"),
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp: any) => {
        const adjustedTimestamp = new Date(timestamp?.seconds * 1000 + Math.floor(timestamp?.nanoseconds / 1000000));
        const formattedTimestamp = dayjs(adjustedTimestamp).format("DD/MM/YYYY, HH:mm");
        return <span>{formattedTimestamp}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: 50,
      render: (text: any, record) => (
        <span>
          <Popover content={contentPopoverAction(record)} trigger="click">
            <Button type="default" style={{ border: 0 }} icon={<MenuOutlined />} />
          </Popover>
        </span>
      ),
    },
  ];

  const contentPopoverAction = (record: any) => (
    <div className="view-bill-popover-action-container">
      <Button icon={<EyeOutlined />} type="text" onClick={() => (window.location.href = `/view-bill/${record.key}`)}>
        {t("myBill.action.view")}
      </Button>
      <Button
        icon={<EditOutlined />}
        type="text"
        onClick={() => {
          window.location.href = `/edit-bill/${record.key}`;
        }}
      >
        {t("myBill.action.edit")}
      </Button>
      <Button
        icon={<ShareAltOutlined />}
        type="text"
        onClick={async () => {
          try {
            await navigator.clipboard.writeText(`${window.location.origin}/view-bill/${record.key}`);
            message.success("คัดลอกลิงค์สำเร็จ");
          } catch (error) {
            message.error(`ไม่สามารถคัดลอกลิงค์ได้`);
          }
        }}
      >
        {t("myBill.action.share")}
      </Button>
      <Button
        icon={<DeleteOutlined />}
        type="link"
        danger
        onClick={() => {
          setIsModaldeleteBillOpen(true);
          setBillSelected(record);
        }}
      >
        {t("myBill.action.delete")}
      </Button>
    </div>
  );

  return (
    <div className="my-bill-container">
      <GoogleAuthen hideElement={false} />
      <div className="wrap-date-range-picker">
        <RangePicker
          style={{
            marginBottom: 14,
          }}
          onChange={(dates) => {
            setDateRange(dates as any);
          }}
        />
      </div>
      {top5People?.length > 0 && (
        <div className="most-people-wrap">
          <Divider orientation="left">{t("myBill.muBillSumPrice")}</Divider>
          <p
            style={{
              fontSize: 24,
              textAlign: "center",
            }}
          >
            {totalBillPrice.toLocaleString()}
          </p>
          <Divider orientation="left">Top 5</Divider>

          <div className="top-5-people">
            {top5People.map((person) => (
              <div className="person-card">
                <div className="person-name">{person[0]}</div>
                <div className="person-total">{Math.ceil(person[1]).toLocaleString()}</div>
              </div>
            ))}
          </div>
        </div>
      )}
      <Divider orientation="left">{t("myBill.myBillTitle")}</Divider>

      <Table dataSource={filteredBills} columns={columns} pagination={false} loading={tableLoading} />

      <Modal
        title={`คุณต้องการลบบิล${billSelected?.billName ? billSelected?.billName : "นี้"}ใช่ไหม? `}
        open={isModaldeleteBillOpen}
        onOk={() => {
          handleDelete(billSelected.key);
        }}
        onCancel={() => {
          setIsModaldeleteBillOpen(false);
        }}
      />
    </div>
  );
}
