import React from "react";
import GoogleAuthen from "../Bill/GoogleAuthen";

export default function Policy() {
  return (
    <div className="bill-container policy-page">
      <GoogleAuthen hideElement={false} />
      <h1>นโยบายการป้องกันข้อมูลส่วนบุคคล</h1>
      <p>
        เว็บไซต์{" "}
        <i>
          <a href="https://checkbillbyjsor.com/">www.checkbillbyjsor.com</a>
        </i>{" "}
        มีความสามารถในการช่วยให้ผู้ใช้ หารเงินค่าใช้จ่ายในกิจกรรมต่างๆ เช่น ทริปการเดินทาง, ปาตี้, หรือการไปร้านอาหาร
        เว็บไซต์{" "}
        <i>
          <a href="https://checkbillbyjsor.com/">www.checkbillbyjsor.com</a>
        </i>{" "}
        มุ่งมั่นที่จะปกป้องข้อมูลส่วนบุคคลของผู้ใช้บริการของเรา
        ดังนั้นเราจึงจัดทำนโยบายการคุ้มครองข้อมูลส่วนบุคคลนี้ขึ้นมา และมีการเก็บข้อมูลดังนี้
      </p>
      <ul>
        <li>
          <span>ข้อมูลบิล</span>
        </li>
        <li>
          <span>ชื่อแต่ละคนในบิล</span>
        </li>
        <li>
          <span>รายการอาหาร</span>
        </li>
        <li>
          <span>ราคาอาหาร</span>
        </li>
        <li>
          <span>ชื่อบิล</span>
        </li>
        <li>
          <span>หมายเลขพร้อมเพย์</span>(ไม่บังคับ)
        </li>
      </ul>
      <p>
        เราจะไม่แบ่งปันข้อมูลส่วนบุคคลของคุณกับบุคคลภายนอกหรือองค์กรอื่นๆ โดยไม่ได้รับความยินยอมจากคุณ
        เว้นแต่จะมีการกำหนดโดยกฎหมาย ข้อมูลที่เราเก็บรวบรวมจะถูกเก็บไว้อย่างปลอดภัย
        และจะใช้เพื่อวัตถุประสงค์การเก็บประวัติของแต่ละบิลเท่านั้น
      </p>
    </div>
  );
}
