import React, { useState } from "react";
import { Button, Input, Row, Col } from "antd";

function Calculator() {
  const [currentValue, setCurrentValue] = useState("");
  const [prevValue, setPrevValue] = useState<string | null>(null);
  const [operator, setOperator] = useState<string | null>(null);

  const handleButtonClick = (value: string) => {
    setCurrentValue((prev) => prev + value);
  };

  const handleOperation = (operation: string) => {
    if (!prevValue) {
      setPrevValue(currentValue);
      setCurrentValue("");
      setOperator(operation);
    } else {
      handleCalculation();
      setOperator(operation);
    }
  };

  const handleCalculation = () => {
    if (operator && prevValue) {
      switch (operator) {
        case "+":
          setCurrentValue(String(parseFloat(prevValue) + parseFloat(currentValue)));
          break;
        case "-":
          setCurrentValue(String(parseFloat(prevValue) - parseFloat(currentValue)));
          break;
        case "x":
          setCurrentValue(String(parseFloat(prevValue) * parseFloat(currentValue)));
          break;
        case "/":
          setCurrentValue(String(parseFloat(prevValue) / parseFloat(currentValue)));
          break;
        default:
          break;
      }
      setPrevValue(null);
      setOperator(null);
    }
  };

  const clear = () => {
    setCurrentValue("");
    setPrevValue(null);
    setOperator(null);
  };

  return (
    <div style={{ marginTop: 15 }}>
      <Row gutter={8}>
        <Col span={24}>
          <Input
            inputMode="decimal"
            value={currentValue}
            onChange={(e) => setCurrentValue(e.target.value)}
            style={{ marginBottom: 10, textAlign: "right", height: 40 }}
          />
        </Col>
      </Row>
      {[
        ["7", "8", "9", "+"],
        ["4", "5", "6", "-"],
        ["1", "2", "3", "x"],
        ["0", ".", "=", "/"],
      ].map((rowItems, rowIndex) => (
        <Row gutter={[16, 16]} key={rowIndex}>
          {rowItems.map((item) => (
            <Col span={6} key={item}>
              <Button
                block
                size="large"
                style={{ margin: "4px 0" }}
                onClick={() => {
                  if (item === "=") {
                    handleCalculation();
                  } else if (["+", "-", "x", "/"].includes(item)) {
                    handleOperation(item);
                  } else {
                    handleButtonClick(item);
                  }
                }}
              >
                {item}
              </Button>
            </Col>
          ))}
        </Row>
      ))}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Button block danger size="large" style={{ marginTop: "15px" }} onClick={clear}>
            Clear
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default Calculator;
