import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ConfigProvider, Modal, Button } from "antd";
import "./i18n/i18n";
import Bill from "./features/Bill/Bill";
import MyBills from "./features/MyBills/MyBills";
import { useTranslation, Trans } from "react-i18next";
import { useEffect, useState } from "react";
import useUserStore from "./features/Bill/userStore";
import DashboardForAdmin from "./features/DashboardForAdmin/DashboardForAdmin";
import Policy from "./features/Policy/Policy";

function App() {
  const { i18n } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { userDetail } = useUserStore();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    const hasConsent = localStorage.getItem("cookieConsent");

    if (!hasConsent && userDetail && window.location.pathname !== "/policy") {
      setIsModalVisible(true);
    }

    if (storedLanguage === "en") {
      i18n.changeLanguage("en");
      document.title = i18n.t("metaTitle");
      const metaDescription = document.querySelector('meta[name="description"]') as HTMLMetaElement;
      if (metaDescription) {
        metaDescription.content = i18n.t("metaDescription");
      }
    }
  }, [i18n, userDetail]);

  const handleOk = () => {
    localStorage.setItem("cookieConsent", "true");
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#1DA57A",
          fontFamily: "Sarabun-Medium",
          fontSize: 15,
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Bill />} />
          <Route path="/view-bill/:billId" element={<Bill />} />
          <Route path="/edit-bill/:billIdEdit" element={<Bill />} />
          <Route path="/my-bills" element={<MyBills />} />
          <Route path="/dashboard" element={<DashboardForAdmin />} />
          <Route path="/policy" element={<Policy />} />
        </Routes>
      </BrowserRouter>
      <Modal
        title={i18n.t("cookieConsentTitle")}
        maskClosable={false}
        closable={false}
        centered
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            {i18n.t("cookieConsentAccept")}
          </Button>,
        ]}
      >
        <Trans
          i18nKey="cookieConsentText"
          components={{
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            privacyPolicyLink: <a href="/policy" target="_blank" style={{ color: "1DA57A" }} />,
          }}
        ></Trans>
      </Modal>
    </ConfigProvider>
  );
}

export default App;
