// src/auth.js
import { getAuth, GoogleAuthProvider, NextOrObserver, signInWithPopup, User } from "firebase/auth";
import { app } from "./firebase_db";

export const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export const loginWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;
    console.log("User info:", user);
    // Additional logic (e.g., storing the user in a database)
  } catch (error) {
    console.error("Error during Google sign-in:", error);
  }
};

export const checkAuthState = (callback: NextOrObserver<User | null>) => {
  auth.onAuthStateChanged(callback);
};

export const logout = () => {
  auth.signOut();
};
