import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { db } from "../../firebase/firebase_db";
import { collection, query, orderBy, startAfter, limit, getDocs } from "firebase/firestore";
import GoogleAuthen from "../Bill/GoogleAuthen";
import dayjs from "dayjs";
import { QueryDocumentSnapshot } from "firebase/firestore";
import useUserStore from "../../features/Bill/userStore";

export default function DashboardForAdmin() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastDocs, setLastDocs] = useState<QueryDocumentSnapshot[]>([]);
  const { userDetail } = useUserStore();
  const [hasMore, setHasMore] = useState(false);
  const [assumedTotal, setAssumedTotal] = useState<any>();

  useEffect(() => {
    fetchBills(currentPage);
  }, [currentPage]);

  const fetchBills = async (page: number) => {
    setLoading(true);

    // Base query with ordering and limiting
    let baseQuery = query(collection(db, "bills"), orderBy("timestamp", "desc"), limit(pageSize));

    // Apply pagination constraint if we're beyond the first page
    if (page !== 1 && lastDocs[page - 2]) {
      baseQuery = query(baseQuery, startAfter(lastDocs[page - 2]));
    }

    try {
      const documentSnapshots = await getDocs(baseQuery);

      const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
      const newLastDocs = [...lastDocs];
      newLastDocs[page - 1] = lastVisible;
      setLastDocs(newLastDocs);

      const billsData = documentSnapshots.docs.map((doc) => ({
        key: doc.id,
        ...doc.data(),
      }));
      setBills(billsData as any);
      if (documentSnapshots.docs.length < pageSize) {
        setHasMore(false);
        setAssumedTotal((page - 1) * pageSize + documentSnapshots.docs.length);
      } else {
        // Assume there's one more page
        setAssumedTotal(page * pageSize + 1);
      }
    } catch (error) {
      console.error("Error fetching bills: ", error);
    } finally {
      setLoading(false);
    }
  };

  const customPagination = {
    current: currentPage,
    pageSize: pageSize,
    total: assumedTotal, // Use the assumed total here
    onChange: (page: React.SetStateAction<number>) => setCurrentPage(page),
  };

  const columns = [
    {
      title: "Bill Name",
      dataIndex: "billName",
      key: "billName",
      render: (text: any, record: any) => (
        <a
          href={`/view-bill/${record.key}`}
          rel="noreferrer"
          target="_blank"
          style={{ textDecoration: "underLine", color: "rgba(0, 0, 0, 0.88)" }}
        >
          {record.billName}
        </a>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp: { toDate: () => any }) =>
        timestamp && dayjs(timestamp.toDate()).format("HH:mm:ss, DD/MM/YYYY"),
    },
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
    },
  ];

  const handleTableChange = (pagination: any) => {
    // Check if the page size has changed
    if (pageSize !== pagination.pageSize) {
      setPageSize(pagination.pageSize);

      // Reset the current page to 1 when page size changes
      setCurrentPage(1);

      // Reset the assumed total to undefined to recalculate based on the new page size
      setAssumedTotal(undefined);

      // Re-fetch data with the new page size
      fetchBills(1);
    } else {
      // Update the current page if only the page has changed
      setCurrentPage(pagination.current);
    }
  };

  if (userDetail?.email === "jaysorasak@gmail.com") {
    return (
      <div className="my-bill-container admin-jsor">
        <GoogleAuthen hideElement={false} />
        <Table
          scroll={{ x: 1000 }}
          className="admin-dashboard-table"
          columns={columns as any}
          dataSource={bills}
          pagination={customPagination}
          loading={loading}
          onChange={handleTableChange as any}
        />
      </div>
    );
  } else {
    return (
      <div className="my-bill-container admin-jsor">
        <GoogleAuthen hideElement={false} />
        <h1>Only admin can access this page</h1>
      </div>
    );
  }
}
