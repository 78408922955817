import { create } from "zustand";

type UserState = {
  userDetail: any;
  isFetching: boolean;
  setUserDetail: (user: any) => void;
  setIsFetching: (fetching: boolean) => void;
};

const useUserStore = create<UserState>((set) => ({
  userDetail: undefined,
  isFetching: true,
  setUserDetail: (user) => set({ userDetail: user }),
  setIsFetching: (fetching) => set({ isFetching: fetching }),
}));

export default useUserStore;
