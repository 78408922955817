import { Button, Upload, message, Spin, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationPlacement } from "antd/es/notification/interface";

const apiKey = "b9d35e8daea84982b066de9edaaff764";
const endpoint = "https://formrecognizercheckbillbyjsor.cognitiveservices.azure.com";
interface Props {
  setDataMenu: (data: any) => {};
  setBillName: (data: any) => {};
  moreBill: boolean;
  userDetail: any;
  setIsModalLoginOpen: any;
}
const UploadBill = ({ setDataMenu, setBillName, moreBill, userDetail, setIsModalLoginOpen }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleUpload = async (file: File) => {
    const base64 = await convertToBase64(file);
    const response = await sendToAzureOCR(base64 as string);
    console.log(response?.data); // Handle the response
    return false; // Prevent upload
  };

  const convertToBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result.split(",")[1]);
        } else {
          reject(new Error("Failed to read file"));
        }
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const openNotification = (placement: NotificationPlacement) => {
    notification.info({
      message: `อัพโหลดบิลสำเร็จ`,
      description: (
        <div className="wrap-notification-message">
          <ul>
            <li>1. กรุณาตรวจสอบ รายการและราคาทั้งหมดอีกครั้ง</li>
            <li>2. กรุณาตรวจสอบ ราคา Service Charge และ Tax ทั้งหมดอีกครั้ง</li>
          </ul>
        </div>
      ),
      placement,
      duration: 10,
    });
  };

  const sendToAzureOCR = async (base64: string) => {
    setIsLoading(true);
    const url = `${endpoint}/formrecognizer/documentModels/prebuilt-receipt:analyze?api-version=2023-07-31`;

    const headers = {
      "Ocp-Apim-Subscription-Key": apiKey,
      "Content-Type": "application/octet-stream",
    };

    // Convert the base64 string back to a Blob
    const blob = base64toBlob(base64, "application/octet-stream");
    try {
      const postResponse = await axios.post(url, blob, { headers });
      const resultUrl = postResponse.headers["operation-location"];
      let getResultResponse;
      // Poll the result URL at regular intervals
      while (true) {
        getResultResponse = await axios.get(resultUrl, { headers: { "Ocp-Apim-Subscription-Key": apiKey } });
        if (getResultResponse.data.status !== "notStarted" && getResultResponse.data.status !== "running") {
          break;
        }
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }

      setIsLoading(false);
      const billItems = getResultResponse.data.analyzeResult.documents[0].fields.Items.valueArray;
      console.log("getResultResponse", getResultResponse.data.analyzeResult.documents[0].fields);
      const billName =
        getResultResponse.data.analyzeResult.documents[0].fields?.MerchantName?.content || "ไม่พบชื่อบิลกดเพื่อแก้ไข";

      const mappedFieldValueItems = billItems.map((item: any, index: number) => {
        const prefix = moreBill ? `${billName === "ไม่พบชื่อบิลกดเพื่อแก้ไข" ? "ไม่พบชื่อ" : billName} - ` : "";

        if (!item.valueObject.TotalPrice?.valueNumber?.toString()) {
          message.error(`ไม่พบราคาสินค้า ${item.valueObject?.Description?.content} กรุณาแก้ไขให้ถูกต้อง`, 10);
        }

        return {
          key: index + 1,
          listName: `${prefix}${item?.valueObject?.Description?.content || "อ่านชื่อรายการไม่ได้"}`,
          price: item.valueObject.TotalPrice?.valueNumber?.toString() || "0",
          each: "0",
          selectedPeople: [],
        };
      });

      const tax = getResultResponse.data.analyzeResult.documents[0].fields?.TotalTax?.valueNumber?.toString();
      const serviceCharge = getResultResponse.data.analyzeResult.documents[0].fields?.Tip?.valueNumber?.toString();

      const subTotal = getResultResponse.data.analyzeResult.documents[0].fields?.Subtotal?.valueNumber?.toString();
      const total = getResultResponse.data.analyzeResult.documents[0].fields?.Total?.valueNumber?.toString();

      if (!moreBill) {
        setBillName(billName);
        localStorage.setItem("billName", billName);
        if (tax && subTotal < total) {
          mappedFieldValueItems.push({
            key: mappedFieldValueItems.length + 1,
            listName: "TAX/VAT",
            price: Math.ceil(Number(tax)).toString(),
            each: "0",
            selectedPeople: [],
          });
        }
        if (serviceCharge) {
          mappedFieldValueItems.push({
            key: mappedFieldValueItems.length + 1,
            listName: "Service Charge",
            price: Math.ceil(Number(serviceCharge)).toString(),
            each: "0",
            selectedPeople: [],
          });
        }
        openNotification("topRight");
        setDataMenu(mappedFieldValueItems);
      } else {
        const existingBillNames = localStorage.getItem("billName") || null;
        localStorage.setItem("billName", existingBillNames + " + " + billName);
        if (tax && subTotal < total) {
          mappedFieldValueItems.push({
            key: mappedFieldValueItems.length + 1,
            listName: `${billName === "ไม่พบชื่อบิลกดเพื่อแก้ไข" ? "ไม่พบชื่อ" : billName}  TAX/VAT`,
            price: Math.ceil(Number(tax)).toString(),
            each: "0",
            selectedPeople: [],
          });
        }
        if (serviceCharge) {
          mappedFieldValueItems.push({
            key: mappedFieldValueItems.length + 1,
            listName: `${billName === "ไม่พบชื่อบิลกดเพื่อแก้ไข" ? "ไม่พบชื่อ" : billName} Service Charge`,
            price: Math.ceil(Number(serviceCharge)).toString(),
            each: "0",
            selectedPeople: [],
          });
        }
        openNotification("topRight");
        setBillName((prev: any) => [...prev, " + " + billName]);
        setDataMenu((prev: any) => {
          const newItems = mappedFieldValueItems.map((item: any, index: any) => ({
            ...item,
            key: prev.length + index + 1,
          }));
          return [...prev, ...newItems];
        });
      }

      return getResultResponse.data;
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      message.error(t("uploadBill.UploadBillErrorMessage"));
    }
  };

  const base64toBlob = (base64: string, contentType: string) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };

  return (
    <Spin spinning={isLoading}>
      {!userDetail ? (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setIsModalLoginOpen(true);
          }}
        >
          {moreBill ? t("uploadBill.uploadMoreBillText") : t("uploadBill.uploadBillText")}
        </Button>
      ) : (
        <Upload beforeUpload={handleUpload} showUploadList={false}>
          <Button type="primary" icon={<PlusOutlined />}>
            {moreBill ? t("uploadBill.uploadMoreBillText") : t("uploadBill.uploadBillText")}
          </Button>
        </Upload>
      )}
    </Spin>
  );
};

export default UploadBill;
